<template>
  <div ref="wrapper" :class="[{ loaded: loaded }]" v-if="hydrated">
    <slot></slot>
  </div>
  <div ref="wrapper" rel="lazy_load" v-else></div>
</template>

<script>
// Doc: https://github.com/maoberlehner/vue-lazy-hydration
import { /*onMounted,*/ ref, watch } from 'vue'
const isBrowser = typeof window !== 'undefined' && window !== null
export default {
  name: 'LazyLoad',
  props: {
    distance: {
      type: Number,
      default: 0.2,
    },
    delay: {
      type: Number,
      default: 0,
    },
    whenIdle: Boolean,
    whenVisible: Boolean,
    callback: Function,
  },
  setup(props) {
    const loaded = ref(false)
    const wrapper = ref(null)
    const hydrated = ref(!isBrowser)
    const hydrate = () => {
      hydrated.value = true
    }

    watch(
      hydrated,
      (hydrate) => {
        if (hydrate && props.didHydrate) props.callback()
      },
      { immediate: true },
    )

    watch(
      [() => props, wrapper, hydrated],
      (
        [{ whenIdle, whenVisible, distance, delay }, wrapper, hydrated],
        _,
        onInvalidate,
      ) => {
        if (hydrated && !wrapper) {
          return
        }

        const cleanupFns = []
        const cleanup = () => {
          cleanupFns.forEach((fn) => {
            fn()
          })
        }

        if (whenVisible) {
          if (wrapper && typeof IntersectionObserver !== 'undefined') {
            const observerOptions = {
              rootMargin: '0px 0px 0px 0px',
              threshold: distance,
            }

            const observer = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.isIntersecting || entry.intersectionRatio === 0) {
                  setTimeout(function () {
                    loaded.value = true
                    hydrate()
                  }, delay)
                }
              })
            }, observerOptions)

            observer.observe(wrapper)

            cleanupFns.push(() => {
              observer.disconnect()
            })
          } else {
            // return hydrate()
          }
        }

        if (whenIdle) {
          if (typeof window.requestIdleCallback !== 'undefined') {
            const idleCallbackId = window.requestIdleCallback(hydrate, {
              timeout: 500,
            })
            cleanupFns.push(() => {
              window.cancelIdleCallback(idleCallbackId)
            })
          } else {
            const id = setTimeout(hydrate, 2000)
            cleanupFns.push(() => {
              clearTimeout(id)
            })
          }
        }

        onInvalidate(cleanup)
      },
      { immediate: true },
    )
    return {
      loaded,
      wrapper,
      hydrated,
    }
  },
}
</script>
